<template>
  <a-modal v-model="modalVisible" title="运行历史" width="75%" :footer="null" :centered="true" @cancel="cancelModal" :bodyStyle="bodyStyle">
    <a-table :columns="tableColumns" :data-source="tableData" row-key="devicestatusid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
      <span slot="statustime" slot-scope="value">
        <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
          <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
        </a-tooltip>
      </span>
      <span slot="temperature" slot-scope="value">
        <span>{{value+"℃"}}</span>
      </span>
    </a-table>
    <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" style="margin-top: 20px; text-align: right;">
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
  </a-modal>
</template>

<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {serviceMode,carStatus,carDirection,doorStatus,hasPassenger} from '@/json/wlwhistory'
import { getDevicestatusListByCondition } from 'A/wlw'
import {getTemperatureByAreaname} from "A/bigdata";
export default{
  name: 'wlwTraceBack',
  mixins: [pagination],
  props: {
    StatusData:{
      default: null
    },
    visible: {
      default: false
    },
  },
  data(){
    return {
      moment,
      modalVisible:false,
      bodyStyle:{
        "padding":"10px"
      },
      queryParams: {
        devicestatusid:'',
        devicenum: '',
        eventstarttime: '',
        eventendtime: '',
      },
      tableColumns:[
        {
          title: '当前服务模式',
          dataIndex: 'servicemode',
          key: 'servicemode',
          ellipsis: true,
        },
        {
          title: '轿厢运行状态',
          dataIndex: 'carstatus',
          key: 'carstatus',
          ellipsis: true,
        },
        {
          title: '轿厢运行方向',
          dataIndex: 'cardirection',
          key: 'cardirection',
          ellipsis: true,
        },
        {
          title: '当前楼层',
          dataIndex: 'car_position',
          key: 'car_position',
          width: 80,
          ellipsis: true,
        },
        {
          title: '关门到位',
          dataIndex: 'doorstatus',
          key: 'doorstatus',
          width: 80,
          ellipsis: true,
        },
        {
          title: '乘客人数',
          dataIndex: 'passenger_num',
          key: 'passenger_num',
          width: 80,
          ellipsis: true,
        },
        // {
        //   title: '是否有人',
        //   dataIndex: 'haspassenger',
        //   key: 'haspassenger',
        //   ellipsis: true,
        // },
        {
          title: '当日人流量',
          dataIndex: 'today_passenger_num',
          key: 'today_passenger_num',
          ellipsis: true,
        },
        {
          title: '累计人流量',
          dataIndex: 'accumulative_passenger_num',
          key: 'accumulative_passenger_num',
          ellipsis: true,
        },
        {
          title: '当日开关门',
          dataIndex: 'today_door_num',
          key: 'today_door_num',
          ellipsis: true,
        },
        {
          title: '累计开关门',
          dataIndex: 'accumulative_door_num',
          key: 'accumulative_door_num',
          ellipsis: true,
        },
        {
          title: '累计运行时间',
          dataIndex: 'accumulative_run_time',
          key: 'accumulative_run_time',
          ellipsis: true,
        },
        {
          title: '累计运行次数',
          dataIndex: 'accumulative_run_num',
          key: 'accumulative_run_num',
          ellipsis: true,
        },
        {
          title: '天气',
          dataIndex: 'text',
          key: 'text',
          width: 40,
          ellipsis: true,
        },
        {
          title: '气温',
          dataIndex: 'temperature',
          key: 'temperature',
          width: 40,
          ellipsis: true,
        },
        {
          title: '生成时间',
          dataIndex: 'statustime',
          key: 'statustime',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'statustime' }
        },
      ],
      tableLoading: false,
      tableData:[]
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods:{
    cancelModal(e){
      this.modalVisible = false
    },
    init() {
      this.getTableData();
    },
    getTemp(){
      let params = {
        devicenum:this.devicenum
      }
      getTemperatureByAreaname(params).then(res=>{
        if(res!=null&&res!=''){
          this.temperature=res.temp.temperature
          this.text=res.temp.text
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let resultstart=(moment(this.StatusData.eventstarttime, 'YYYYMMDDHHmmss').valueOf())/1000-120;
      let starttime=moment(resultstart * 1000).format('YYYYMMDDHHmmss');
      let result=(moment(this.StatusData.eventstarttime, 'YYYYMMDDHHmmss').valueOf())/1000+120;
      let endtime=moment(result * 1000).format('YYYYMMDDHHmmss');
      let params = {
        ...this.queryParams,
        starttime: starttime,
        endtime: endtime,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getDevicestatusListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(v=>{
            let datas = JSON.parse(v.statusdata)
            let ishuichuan = datas['运行状态']

            if(ishuichuan){
              return{
                ...v,
                servicemode:"",
                carstatus:datas["运行状态"],
                cardirection:datas["运行方向"],
                car_position:datas["楼层"],
                doorstatus:datas["开关门状态"],
                haspassenger:datas["是否有人"],
              }
            }else{
              let {service_mode,car_status,car_direction,door_status,has_passenger,car_position,accumulative_door_num, today_passenger_num, passenger_num, today_door_num,
                accumulative_passenger_num, door_zone, accumulative_run_num, accumulative_run_time,temperature, text,} = JSON.parse(v.statusdata)
              return{
                ...v,
                car_position,
                servicemode:serviceMode[service_mode],
                carstatus:carStatus[car_status],
                cardirection:carDirection[car_direction],
                doorstatus:doorStatus[door_status],
                haspassenger:hasPassenger[has_passenger],
                accumulative_door_num,
                has_passenger,
                today_passenger_num,
                passenger_num,
                today_door_num,
                accumulative_passenger_num,
                door_zone,
                accumulative_run_num,
                accumulative_run_time,
                temperature:parseFloat(temperature)+"℃",
                text,
              }
            }
          });
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
  }
}
</script>